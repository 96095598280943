<template>
  <popup
    ref="popupRefs"
    :async="true"
    width="450px"
    :title="identity ? '编辑站点' : '新增站点'"
    confirm-button="保存"
    @confirm="onConfirm"
    @close="onClose"
  >
    <el-form
      ref="formRefs"
      v-loading="loading"
      :model="form"
      :rules="rules"
      label-width="90px"
      size="small"
    >
      <el-form-item label="站点名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入站点名称"></el-input>
      </el-form-item>
      <el-form-item label="站点网址" prop="url">
        <div>
          <el-input v-model="form.url" placeholder="请输入站点网址"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="客户邮箱" prop="email">
        <div>
          <el-input
            v-model="form.email"
            placeholder="请输入客户邮箱"
          ></el-input>
          <div class="form-tips">多个邮箱使用英文逗号分隔</div>
        </div>
      </el-form-item>
      <el-form-item label="站点状态" prop="status">
        <el-switch
          v-model="form.status"
          :active-value="1"
          :inactive-value="0"
          active-text="正常"
          inactive-text="停用"
        />
      </el-form-item>
    </el-form>
  </popup>
</template>

<script>
import Popup from "@/components/popup/index.vue";

import { apiDetailSite, apiAddSite, apiEditSite } from "@/api/site";

export default {
  components: {
    Popup,
  },
  data() {
    const urlValidator = (rule, value, callback) => {
      const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
      if (!regex.test(value)) callback(new Error("请输入正确的网址，如：https://www.example.com"));
      callback();
    };
    // const emailValidator = (rule, value, callback) => {
    //   const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    //   if (!regex.test(value))
    //     callback(new Error("请输入正确的邮箱地址"));
    //   callback();
    // };
    return {
      identity: null,
      loading: false,
      form: {
        name: "",
        url: "",
        email: "",
        status: ""
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入站点名称",
            trigger: ["blur"],
          },
        ],
        url: [
          {
            required: true,
            message: "请输入站点网址",
            trigger: ["blur"],
          },
          {
            validator: urlValidator,
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入客户邮箱",
            trigger: ["blur"],
          },
          // {
          //   validator: emailValidator,
          //   trigger: "blur",
          // },
        ],
      },
    };
  },
  computed: {},
  methods: {
    getDetail() {
      this.loading = true;
      apiDetailSite({ id: this.identity }).then((resp) => {
        Object.keys(resp).map((item) => {
          this.$set(this.form, item, resp[item]);
          this.loading = false;
        });
      });
    },
    openDialog(id) {
      this.identity = id;
      if (this.identity) {
        this.getDetail();
      }
      this.$refs.popupRefs.open();
    },
    closeDialog() {
      this.$refs.popupRefs.close();
    },
    onClose() {
      this.$nextTick(() => {
        this.$refs.formRefs.clearValidate();
        this.$refs.formRefs.resetFields();
      });
    },
    onConfirm() {
      this.$refs.formRefs.validate((valid) => {
        if (!valid) return;
        const api = this.identity
          ? apiEditSite(this.form)
          : apiAddSite(this.form);
        api.then(() => {
          this.closeDialog();
          this.$message({
            type: "success",
            message: "保存成功！",
          });
          this.$emit("refresh");
        });
      });
    },
  },
};
</script>
